.navbar img {
  display: inline;
}

.navbar .collapse {
  visibility: unset !important;
}

li.page-item a,
a {
  text-decoration: none;
  color: darkred;
}

div.pink,
h5.pink {
  color: #CF4A72;
}
